import { Injectable } from "@angular/core";
import
  {
    ErrorHandlerCreator, ResponseErrorHandler
  } from "./response-error-handler.service";
import
  {
    APIService, unwrapResponse
  } from "./api.service";
import
  {
    Observable, catchError
  } from "rxjs";
import { HttpParams } from "@angular/common/http";
import {
 Document, DocumentUpdate, PartialData
} from "../models";

@Injectable({
  providedIn : "root"
})
export class DocumentService
{
  static readonly url = "Document";

  private handleError: ErrorHandlerCreator;

  constructor(private readonly api: APIService,
    private readonly responseErrorHandler: ResponseErrorHandler
  )
  {
    this.handleError = this.responseErrorHandler.createErrorHandler("DocumentService");
  }

  getDocumentById(id: string): Observable<Blob | null>
  {
    let params = new HttpParams();

    params = params.set("documentId", id);

    const options = {
      params : params, responseType : "blob", asset : true
    };

    return this.api.get<Blob | null>(DocumentService.url, options).pipe(
      unwrapResponse(),
      catchError(this.handleError("getDocumentById", null))
    );
  }

  getDocumentsByPersonId(personId: string, skip: number, take: number):
    Observable<PartialData<Document> | null>
  {
    const options = { params: new HttpParams().set("personId", personId).set("skip", skip).set("take", take) };

    return this.api.get<PartialData<Document>>(`${DocumentService.url}/GetDocumentsByPersonId`, options).pipe(
      unwrapResponse(),
      catchError(this.handleError("getDocumentsByPersonId", null))
    );
  }

  uploadFile(studentId: string, newDocument?: DocumentUpdate): Observable<boolean | null>
  {
    const fd: FormData = new FormData();

    let i = 0;

    if (newDocument && newDocument.File != null)
    {
      fd.append("documents[" + i + "].Description", newDocument.Description? newDocument.Description:"");
      fd.append("documents[" + i + "].TypeID", newDocument.TypeID);
      fd.append("documents[" + i + "].File", newDocument.File, newDocument.File.name);
    }
    i++;

    return this.api.post<boolean | null>(`${DocumentService.url}/Upload`, fd, { params: { studentId }}).pipe(
      unwrapResponse(),
      catchError(this.handleError("Upload", null))
    );
  } 
  
  updateDocument(document?: DocumentUpdate)
    : Observable<boolean | null>
  {
    const fd: FormData = new FormData();

    if(document!=null)
      {
        fd.append("document.Id", document.Id);
        fd.append("document.TypeID", document.TypeID);
        fd.append("document.SubjectPersonalityID", document.SubjectPersonalityID);
        fd.append("document.OwnerID", document.OwnerID);
        fd.append("document.ValidFrom", document['ValidFrom'] ? new Date(document['ValidFrom']).toISOString():"");
        fd.append("document.ValidThru", document['ValidThru'] ? new Date(document['ValidThru']).toISOString():"");
        fd.append("document.InstitutionID", document.InstitutionID);
        fd.append("document.Description", document.Description); 
        document.File? fd.append("document.File", document.File, document.File.name):null;  
      }
      
      return this.api.post<boolean | null>("Document/UpdateDocument", fd).pipe(
        unwrapResponse(),
        catchError(this.handleError("updateDocument", null))
      );
  }
  
  getDocumentUpdate(Id: string): Observable<DocumentUpdate>
  {
    return this.api.get<DocumentUpdate>(
      DocumentService.url + "/GetDocumentForUpdate?id=" + Id
    ).pipe(
      unwrapResponse(),
      catchError(this.handleError("getDocumentForUpdate"))
    );
  }

}